<template>
	<Metier name="ordonnancement" title="Ordonnancement" :main-menu="[{title: 'Contremaître Ordonnancement', missions: ['Etablit le Plan Hebdomadaire de Production (PHP) selon les consignes de sa hiérarchie', 'S\'assure de la bonne mise en œuvre du PHP', 'Coordonne les événements susceptibles d\'impacter l\'ordonnancement (travaux, arrêts…)', 'Evalue la production, communique les bilans et recherche l\'optimisation de la logistique de fabrication'], competences: ['Expérience significative de la production en raffinerie', 'Bonne connaissance des installations, des schémas de raffinage et des produits du site', 'Bonne connaissance de l\'économie pétrolière et des réglementations douanières', 'Bonne connaissances en informatique'], qualites: ['Rigueur', 'Organisation', 'Disponibilité', 'Leadership']}, {title: 'Technicien Ordonnancement', missions: ['S\'assure de la bonne mise en œuvre des consignes de fabrication (qualité, quantité…)', 'Recherche la valorisation économique optimale sur les lignes de production', 'Programme les expéditions de produits en collaborations avec les autres services', 'Analyse et reporte les incidents de qualité lors de la fabrication ou de l\'expédition des produits'], competences: ['Formation technique avec expérience', 'Expérience significative dans les emplois techniques de la raffinerie (mouvements des produits, production, laboratoire, procédés)', 'Bonne connaissance des installations, des schémas de raffinage et des produits du site', 'Bonne connaissances en informatique'], qualites: ['Rigueur', 'Organisation', 'Disponibilité']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
